import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import ScrollLoader from 'vue-scroll-loader';
import money from 'v-money';
import utils from '@/utils';

Vue.use(ScrollLoader);
Vue.use(money);

var FILES_URL = 'https://files.beta.olhadela.com.br/';
var SITE_URL = process.env.NODE_ENV === "production" ? "https://olhade.la" : "http://localhost:8080";
var API_URL = process.env.NODE_ENV === "production" ? "https://api.olhadela.digital" : "http://localhost:8000";

var axios = require('axios').create({
  baseURL: API_URL,
  withCredentials: true,
});

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;
Vue.prototype.$utils = utils;
Vue.prototype.$FILES_URL = FILES_URL;
Vue.prototype.$SITE_URL = SITE_URL;

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
