var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:('background-color: #f5f5f5;'),attrs:{"id":"app"}},[_c('v-card',{class:!_vm.isMobile && _vm.$route.name != 'download'
      ? 'd-flex fill-height align-center justify-center grey darken-3'
      : 'my-auto',attrs:{"elevation":"0","tile":"","width":!_vm.isMobile && _vm.$route.name != 'download' ? '100%' : ''}},[_c('div',{class:!_vm.isMobile ? 'flex-column' : '',style:(!_vm.isMobile && _vm.$route.name != 'download'
        ? 'max-height: 100%; min-width: 800px; max-width: 800px; position: relative; background-color: #f5f5f5;'
        : 'background-color: #f5f5f5;')},[(_vm.$route.name && _vm.$route.name != 'download' && _vm.name != '')?_c('v-app-bar',{style:(!_vm.isMobile && _vm.$route.name != 'download'
            ? 'position: absolute; max-width: 800px;'
            : ''),attrs:{"fixed":"","dark":"","color":"primary","height":"60px"}},[_c('router-link',{staticClass:"d-flex",attrs:{"to":'/' + _vm.$route.params.businessId}},[_c('v-img',{staticClass:"mr-2",staticStyle:{"border-radius":"50px"},attrs:{"src":_vm.logo_url != null ? _vm.logo_url : '@/assets/logo.png',"cover":"","max-height":"25","max-width":"25"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.name),expression:"name"}],staticClass:"black--text",on:{"click":function($event){_vm.$route.name != 'about'
            ? _vm.$router.push('/' + _vm.$route.params.businessId)
            : ''}}},[_c('b',[_vm._v(_vm._s(_vm.firstUpperCase(_vm.name)))])]),_c('v-spacer'),_c('v-fade-transition',[(!_vm.hidden)?_c('v-text-field',{ref:"search",staticClass:"lighten-2 black--text",staticStyle:{"max-width":"300px"},attrs:{"autofocus":"","light":"","color":"grey","placeholder":'Pesquisar ' + _vm.serviceORproduct,"hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchProduct()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),(_vm.$route.name != 'about' && _vm.$route.name != 'download')?_c('v-btn',{attrs:{"icon":"","active-class":"no-active"},on:{"click":function($event){!_vm.hidden && _vm.search == _vm.lastSearch
            ? (_vm.hidden = !_vm.hidden)
            : !_vm.hidden
              ? _vm.searchProduct()
              : (_vm.hidden = !_vm.hidden)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-magnify")])],1):_vm._e()],1):_vm._e(),(_vm.$route.name && _vm.$route.name != 'download' && _vm.name != '')?_c('div',{staticStyle:{"height":"60px"}}):_vm._e(),_c('v-fade-transition',{attrs:{"duration":"300","mode":"out-in"}},[_c('router-view',{style:(!_vm.isMobile && _vm.$route.name != 'download'
            ? 'max-height: 600px; min-height: 600px; overflow-y:auto'
            : 'height: 100%'),attrs:{"transition":"scroll-y-reverse-transition"}})],1),_c('v-snackbar',{attrs:{"text":"","color":_vm.messageSnackbar.color,"timeout":3000},model:{value:(_vm.messageSnackbar.showing),callback:function ($$v) {_vm.$set(_vm.messageSnackbar, "showing", $$v)},expression:"messageSnackbar.showing"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.messageSnackbar.message)+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.messageSnackbar.showing = false}}},[_vm._v(" ok ")])],1)]),(_vm.$route.name && _vm.$route.name != 'download' && _vm.name != '')?_c('Footer'):_vm._e(),(_vm.$route.name != 'download' && !_vm.$route.name)?_c('div',{staticClass:"\n          d-flex\n          align-center\n          justify-center\n          fill-height\n          font-weight-bold\n        "},[_vm._v(" Página não encontrada! ")]):_vm._e()],1)]),_c('CookiesWarning')],1)}
var staticRenderFns = []

export { render, staticRenderFns }