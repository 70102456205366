var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.scrollingLeft == true)?_c('div',{class:'d-flex justify-space-between'},[_c('v-carousel',{attrs:{"continuous":"","cycle":"","height":"350px","interval":4000,"delimiter-icon":"mdi-minus","show-arrows":false,"hide-delimiters":""}},_vm._l((_vm.spotlightProducts),function(product,i){return _c('v-carousel-item',{key:i},[_c('v-card',{class:'my-1 d-flex flex-column',attrs:{"tile":"","width":"100%","max-height":"340","to":{
          name: 'product',
          params: {
            businessId: _vm.$route.params.businessId,
            productId: product.website_identification,
          },
        }}},[_c('div',{staticClass:"d-flex align-center white--text text-caption rounded ma-2",staticStyle:{"min-height":"7px"},style:('background-color: #' + product.category.color)}),_c('div',{staticClass:"my-2 flex-grow-1 d-flex align-center",staticStyle:{"min-height":"100px"}},[(_vm.loadingSpotlight)?_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_c('v-progress-circular',{attrs:{"color":"secondary","indeterminate":"","size":50,"width":2}})],1):(product.images.length > 0)?_c('v-img',{attrs:{"max-height":"200px","max-width":"100%","contain":"","src":_vm.$FILES_URL + product.images[0].url}}):_vm._e()],1),_c('div',{staticClass:"ma-2 d-flex align-center"},[_vm._v(" "+_vm._s(_vm.endDescription(product.name))+" ")]),_c('v-divider'),(product.price != 0.00)?_c('div',{staticClass:"pa-2 d-flex flex-wrap justify-end"},[_c('v-spacer'),_c('span',{style:({
              color: product.promotion_price ? '#DD6666' : '#000',
              textDecoration: product.promotion_price
                ? 'line-through'
                : 'none',
            })},[_c('span',{staticStyle:{"font-weight":"600","font-size":"12px !important"}},[_vm._v(" R$ ")]),_vm._v(" "+_vm._s(_vm.$utils.formatMoney(product.price))+" ")]),(product.promotion_price)?[_c('div',{staticClass:"ml-2"},[_c('span',{staticStyle:{"font-weight":"600","font-size":"12px !important"}},[_vm._v(" R$ ")]),_c('b',[_vm._v(" "+_vm._s(_vm.$utils.formatMoney(product.promotion_price))+" ")])])]:_vm._e()],2):_vm._e()],1)],1)}),1)],1):_vm._e(),(_vm.scrollingLeft == false)?_c('div',{class:'d-flex justify-space-between',style:({ 'flex-wrap': 'wrap', })},[_vm._l((_vm.products),function(product,i){return _c('v-card',{key:i,class:'my-1 d-flex flex-column',style:(_vm.$vuetify.breakpoint.width >= 690 ? 'max-width: 420px' : ''),attrs:{"tile":"","width":_vm.$vuetify.breakpoint.width >= 300 ? '49%' : '100%',"max-height":"38                        0","to":{
        name: 'product',
        params: {
          businessId: _vm.$route.params.businessId,
          productId: product.website_identification,
        },
      }}},[_c('div',{staticClass:"d-flex align-center white--text text-caption rounded ma-2",staticStyle:{"min-height":"7px"},style:('background-color: #' + product.category.color)}),(product.images.length > 0)?_c('v-img',{staticClass:"ma-2",attrs:{"src":_vm.$FILES_URL + product.images[0].url,"aspect-ratio":"1"}}):_vm._e(),_c('div',{staticClass:"ma-2",staticStyle:{"min-height":"100px"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.lowerTitle(product.name, product.description))}})]),_c('v-divider'),_c('div',{staticClass:"pa-2 d-flex flex-wrap justify-end"},[_c('v-spacer'),_c('span',{style:({
          color: product.promotion_price ? '#DD6666' : '#000',
          textDecoration: product.promotion_price ? 'line-through' : 'none',
          fontWeight: product.promotion_price ? '400' : '600',
        })},[(product.price != 0.00)?_c('span',{staticStyle:{"font-weight":"600","font-size":"12px !important"}},[_vm._v(" R$ ")]):_vm._e(),(product.price != 0.00)?_c('span',[_vm._v(" "+_vm._s(_vm.$utils.formatMoney(product.price)))]):_vm._e()]),(product.promotion_price)?[_c('div',{staticClass:"ml-2"},[_c('span',{staticStyle:{"font-weight":"600","font-size":"12px !important"}},[_vm._v(" R$ ")]),_c('b',[_vm._v(" "+_vm._s(_vm.$utils.formatMoney(product.promotion_price))+" ")])])]:_vm._e()],2)],1)}),(!_vm.fullyLoaded)?_c('div',{staticClass:"py-5 d-flex align-center text-center"},[_c('div',{staticClass:"d-flex align-center"},[(_vm.tryAgain)?_c('div',{staticClass:"py-5 d-flex align-center text-center"},[_vm._v(" Erro inesperado ao carregar "+_vm._s(_vm.$offerType())+"s. "),_c('br'),_c('v-btn',{staticClass:"mt-2",attrs:{"small":"","color":"error","tile":"","dark":""},on:{"click":function($event){return _vm.getData()}}},[_vm._v(" Tentar novamente ")])],1):_c('scroll-loader',{attrs:{"loader-method":_vm.getData,"loader-disable":_vm.fullyLoaded}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"width":2}})],1)],1)]):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }