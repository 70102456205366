import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, { directives: { ripple } })

const light = {
  primary: '#FFFFFF',
  secondary: '#FA6616',
  tertiary: '#FF9800',
  accent: '#e91e63',
  info: '#00CAE3',
  background: '#f9f9f9',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',
}

const dark = {
  primary: '#FFFFFF',
  secondary: '#FA6616',
  tertiary: '#FF9800',
  accent: '#e91e63',
  info: '#00CAE3',
  background: '#f0f0f0',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',
}

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 1140 },
  theme: {
    themes: {
      dark: dark,
      light: light,
    },
  },
})
