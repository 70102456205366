<template>
  <v-btn class="flex-grow-1" :class="
    favorite_button.selected_icon == 'E-mail'
  || favorite_button.selected_icon == 'Telefone'
  || favorite_button.selected_icon == 'Site'
  ? 'black--text'
  : 'white--text'" :color="
            favorite_button.selected_icon == 'Facebook' ? 'blue lighten-1' :
            favorite_button.selected_icon == 'Twitter' ? 'blue accent-4' :
            favorite_button.selected_icon == 'Instagram' ? 'pink accent-3' :
            favorite_button.selected_icon == 'TikTok' ? 'blue-grey darken-4' :
            favorite_button.selected_icon == 'Youtube' ? 'deep-orange accent-4' :
            favorite_button.selected_icon == 'Whatsapp' ? 'green' :
            favorite_button.selected_icon == 'Telegram' ? 'blue' :
            favorite_button.selected_icon == 'Outro' ? 'grey darken-4' : 'primary'"
    large dark v-if="favorite_button" @click="openLink(favorite_button)" target="_blank">
    <v-img v-if="favorite_button.icon_url" :src="favorite_button.icon_url" contain max-height="25" max-width="25"
      class="mr-2" />
    <v-icon v-else-if="favorite_button.selected_icon == 'E-mail'" class="mr-1">mdi-email</v-icon>
    <v-icon v-else-if="favorite_button.selected_icon == 'Telefone'" class="mr-1">mdi-phone</v-icon>
    <v-icon v-else-if="favorite_button.selected_icon == 'Site'" class="mr-1">mdi-web</v-icon>
    <v-icon v-else class="mr-1">mdi-store-plus</v-icon>
    {{ favorite_button.selected_icon }}
  </v-btn>
</template>

<script>
export default {
  name: "MainActionButton",
  props: {
    color: {
      type: String,
    },
    product_name: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    favorite_button: null,
  }),

  methods: {
    removeCharsNumber(number) {
      return number.replace(/\D/g, "");
    },
    open() {
      this.dialog = true;
    },

    newTab(url) {
      window.open(url, '_blank');
    },

    openLink(action_button) {
      if (this.product_name != null && action_button.selected_icon != "Whatsapp" && action_button.selected_icon != "Outro") {
        navigator.clipboard.writeText("Tenho interesse no produto " + this.product_name + " " + this.$SITE_URL + this.$route.fullPath);
        this.$showMessage('secondary', 'Mensagem copiada para área de transferência!');
      }
      if (action_button.selected_icon == "Whatsapp"){
        if (this.product_name != null)
        {
          return this.newTab("http://api.whatsapp.com/send?phone=55" +
          this.removeCharsNumber(action_button.data) + "&text=" + "Tenho interesse no produto " + this.product_name + " " + this.$SITE_URL + this.$route.fullPath);
        }
        else{
          return this.newTab("http://api.whatsapp.com/send?phone=55" +
          this.removeCharsNumber(action_button.data));
        }
      }
      else if (action_button.selected_icon == "E-mail"){
        return window.location.href =  "mailto:" + action_button.data;
      }
      else if (action_button.selected_icon == "Telefone")
        return window.location.href = "tel:" + this.removeCharsNumber(action_button.data);
      else if (action_button.selected_icon == "Twitter"){
        return this.newTab("https://twitter.com/" + action_button.data.substring(1));
      }
      else if (action_button.selected_icon == "Instagram"){
        return this.newTab("https://www.instagram.com/" + action_button.data.substring(1));
      }
      else if (action_button.selected_icon == "TikTok"){
        return this.newTab("https://www.tiktok.com/" + action_button.data);
      }
      else if(action_button.selected_icon == "Facebook" || action_button.selected_icon == "Youtube" || action_button.selected_icon == "Site"){
        return this.newTab(action_button.data);
      }
      else if (action_button.selected_icon == "Telegram"){
        return this.newTab("https://t.me/" + action_button.data.substring(1));
      }
      else {
        navigator.clipboard.writeText(action_button.data);
        this.$showMessage('secondary', 'Copiado para a área de transferência!');
      }
    },

    getFavoriteButton() {
      this.loading = true;
      this.tryAgain = false;

      this.$axios
        .get("/web/action-buttons-favorite/" + this.$route.params.businessId)
        .then((response) => {
          this.favorite_button = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$showMessage("error", this.$offerType()+" inexistente");
          } else if (error.response.status !== 403) {
            this.tryAgain = true;
            console.log(error);
            this.$showMessage("error", "Erro ao carregar o botão de favorito");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getFavoriteButton();
  },
};
</script>
