import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'download',
    component: () => import('../landing/Download.vue')
  },
  {
    path: '/:businessId',
    name: 'about',
    component: About
  },
  {
    path: '/:businessId/ofertas',
    name: 'products',
    component: () => import('../views/Products.vue')
  },
  {
    path: '/:businessId/:productId',
    name: 'product',
    component: () => import('../views/ViewProduct.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
