const tinycolor = require("tinycolor2");

export default {
    moneyFormat: {
        decimal: ',',
        thousands: '.',
        precision: 2,
    },
    parseTextToHtml(txt){
        return txt.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    },
    textColorForBackground(color){
        return tinycolor(color).getBrightness() > 128 ? 'black':'white';
    },
    formatMoney(value){
        let [num, decimal] = value.toFixed(2).split('.');
        let negative = false;
        if (num[0] == '-'){
            num = num.slice(1);
            negative = true;
        }
        let newNum = "";
        while (num.length > 3){
            newNum = '.' + num.slice(-3) + newNum;
            num = num.slice(0, -3);
        }
        return `${negative ? '-':''}${num}${newNum},${decimal}`;
    },
    formattedMoneyToDecimal(str){
        return str.replaceAll(/\./g, '').replace(',', '.');
    },
    formatDate(date){
        if (!date) return undefined;
        if (typeof date != Date){
            if (date.length == '2022-00-00'.length){
                date += " 00:00:00";
            }
            date = new Date(date);
        }
        return date.toLocaleDateString('pt-BR');
    },
    formatTime(time){
        if (typeof time != Date){
            time = new Date(time);
        }
        return time.toLocaleTimeString('pt-BR').split(':').slice(0, 2).join(':');
    },
    formatDateTime(date){
        if (typeof date != Date){
            date = new Date(date);
        }
        return this.formatDate(date) + " " + this.formatTime(date);
    },
};
