<template>
  <v-app id="app" :style="'background-color: #f5f5f5;'">
    <v-card elevation="0" tile :width="!isMobile && $route.name != 'download' ? '100%' : ''" :class="
      !isMobile && $route.name != 'download'
        ? 'd-flex fill-height align-center justify-center grey darken-3'
        : 'my-auto'
    ">
      <div :class="!isMobile ? 'flex-column' : ''" :style="
        !isMobile && $route.name != 'download'
          ? 'max-height: 100%; min-width: 800px; max-width: 800px; position: relative; background-color: #f5f5f5;'
          : 'background-color: #f5f5f5;'
      ">
        <v-app-bar v-if="$route.name && $route.name != 'download' && name != ''" fixed dark color="primary"
          height="60px" :style="
            !isMobile && $route.name != 'download'
              ? 'position: absolute; max-width: 800px;'
              : ''
          ">
          <router-link :to="'/' + $route.params.businessId" class="d-flex">
            <v-img :src="logo_url != null ? logo_url : '@/assets/logo.png'" cover max-height="25" max-width="25"
              class="mr-2" style="border-radius: 50px" />
          </router-link>
          <div v-show="name" class="black--text" @click="
            $route.name != 'about'
              ? $router.push('/' + $route.params.businessId)
              : ''
          ">
            <b>{{ firstUpperCase(name) }}</b>
          </div>
          <v-spacer />
          <v-fade-transition>
            <v-text-field v-if="!hidden" autofocus light color="grey" class="lighten-2 black--text" ref="search"
              @keydown.enter="searchProduct()" v-model="search" :placeholder="'Pesquisar ' + serviceORproduct"
              style="max-width: 300px" hide-details />
          </v-fade-transition>

          <v-btn v-if="$route.name != 'about' && $route.name != 'download'" icon @click="
            !hidden && search == lastSearch
              ? (hidden = !hidden)
              : !hidden
                ? searchProduct()
                : (hidden = !hidden)
          " active-class="no-active">
            <v-icon color="black">mdi-magnify</v-icon>
          </v-btn>
        </v-app-bar>
        <div style="height: 60px" v-if="$route.name && $route.name != 'download' && name != ''" />
        <v-fade-transition duration="300" mode="out-in">
          <router-view :style="
            !isMobile && $route.name != 'download'
              ? 'max-height: 600px; min-height: 600px; overflow-y:auto'
              : 'height: 100%'
          " transition="scroll-y-reverse-transition" />
        </v-fade-transition>
        <v-snackbar text v-model="messageSnackbar.showing" :color="messageSnackbar.color" :timeout="3000">
          <div class="d-flex justify-space-between align-center">
            <span class="font-weight-medium">
              {{ messageSnackbar.message }}
            </span>
            <v-btn text @click="messageSnackbar.showing = false"> ok </v-btn>
          </div>
        </v-snackbar>
        <Footer v-if="$route.name && $route.name != 'download' && name != ''" />
        <div v-if="$route.name != 'download' && !$route.name" class="
            d-flex
            align-center
            justify-center
            fill-height
            font-weight-bold
          ">
          Página não encontrada!
        </div>
      </div>
    </v-card>

    <CookiesWarning />

  </v-app>
</template>

<script>
import Vue from "vue";
import Footer from "./components/Footer.vue";
import CookiesWarning from "@/components/CookiesWarning.vue";

export default {
  components: { Footer, CookiesWarning, },
  data: () => ({
    messageSnackbar: {
      showing: false,
      message: "",
      color: "",
    },
    name: "",
    navigation: "Olhadela",
    hidden: true,
    search: "",
    lastSearch: "",
    logo_url: "",
    serviceORproduct: "Produto",
  }),
  computed: {
    maxWidth() {
      return 820;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width <= this.maxWidth;
    },
  },

  methods: {
    searchProduct() {
      this.lastSearch = this.search;
      this.$setSearch(this.search);
    },
    showMessage(color, msg) {
      this.messageSnackbar.showing = false;
      this.$nextTick(() => {
        this.messageSnackbar.color = color;
        this.messageSnackbar.message = msg;
        this.messageSnackbar.showing = true;
      });
    },
    firstUpperCase(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    confirmDialog(title, subtitle, deleteFunction) {
      this.$refs.confirmDialog.open(title, subtitle, deleteFunction);
    },
    getBusinessName() {
      this.loading = true;
      this.tryAgain = false;
      if (this.$route.params.businessId)
        this.$axios
          .get("/web/business-name/" + this.$route.params.businessId)
          .then((response) => {
            this.name = response.data.name;
            this.setLogo(response.data.logo_url);
            if (response.data.offer_type == "service") {
              this.serviceORproduct = "Serviço";
            } else {
              this.serviceORproduct = "Produto";
            }
          })
          .catch(() => {
            this.tryAgain = true;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    setNavigation(title) {
      this.navigation = title;
    },
    setLogo(logo_url) {
      this.logo_url = this.$FILES_URL + logo_url;
      document.head.querySelector("link[rel*='icon']").href = this.logo_url;
    },

    getOfferType() {
      return this.serviceORproduct;
    },
  },

  created() {
    this.getBusinessName();
    Vue.prototype.$showMessage = this.showMessage;
    Vue.prototype.$setNavigation = this.setNavigation;
    Vue.prototype.$offerType = this.getOfferType;
    Vue.prototype.$isMobile = this.isMobile;
  },

  mounted() {
    this.getBusinessName();
  },

  watch: {
    "$route.params.businessId"() {
      this.getBusinessName();
    },
    "$route.name"() {
      if (this.$route.name != "product") {
        document.title = this.firstUpperCase(this.name);
        if (this.$route.name == "download") {
          document.title = "Olhadela";
        }
      }
    },
    navigation() {
      if (this.navigation)
        document.title =
          this.firstUpperCase(this.name) + " | " + this.navigation;
      else document.title = this.navigation;
    },
    name() {
      if (
        (this.name != "" && this.$route.name == "about") ||
        this.$route.name == "products"
      ) {
        document.title = this.firstUpperCase(this.name);
      } else if (this.$route.name == "download") {
        document.title = "Olhadela";
      }
    },
  },
};
</script>


<style>
.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.item-scroll {
  display: inline-block;
}
</style>
