<template>
  <div :style="this.$route.name != 'download' ? 'margin-top: 64px' : ''">
    <v-card dark height="64px" class="py-1 px-2 d-flex align-center"
      :style="!isMobile ? 'max-width: 800px; position: relative' : ''"
      style="position: fixed; top: 100%; margin-top: -64px; width: 100%" tile>
      <div class="align-center d-flex justify-space-between px-2" style="width: 100%">
        <v-img @click="dialog = true" contain max-width="90px" src="../assets/logo-text-white.svg" class="ma-0 pa-0">
        </v-img>
        <v-btn :to="'/' + $route.params.businessId" icon active-class="no-active">
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn icon :to="{
          name: 'products',
          params: {
            businessId: $route.params.businessId,
          },
        }" active-class="no-active">
          <v-icon>mdi-package-variant</v-icon>
        </v-btn>
        <v-btn :disabled="contacts.length == 0" icon @click="contactDialog = true" active-class="no-active">
          <v-icon>mdi-human-greeting-proximity</v-icon>
        </v-btn>
        <ActionButtonsDialog :callBackButtons="setContacts" :dialog="contactDialog"
          :callBackDialog="setContactDialog" />
        <v-btn @click="shareStore()" icon active-class="no-active">
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>
      </div>
      <template>
        <v-dialog v-model="dialog" width="240px" max-width="350">
          <v-card class="pt-2">
            <div class="pa-3">
              <h3 class="text-center">Quer conhecer mais sobre o olhadela?</h3>
              <v-card-actions class="justify-space-between mt-5">
                <v-btn width="49%" color="grey" dark @click="dialog = false">Não</v-btn>
                <v-btn width="49%" color="primary" class="black--text" @click="dialog = false" href='/'>Sim</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
      </template>
    </v-card>
  </div>
</template>


<script>
import ActionButtonsDialog from "./ActionButtonsDialog.vue";
export default {
  components: { ActionButtonsDialog },
  name: "Footer",
  data: () => ({
    dialog: false,
    contactDialog: false,

    contacts: [],
  }),
  computed: {
    maxWidth() {
      return 600;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width <= this.maxWidth;
    },
  },
  methods: {
    setContactDialog(value) {
      this.contactDialog = value;
    },

    setContacts(contacts) {
      this.contacts = contacts;
    },

    shareStore() {
      navigator.share({
        url: window.location.protocol + '//'
          + window.location.host + '/'
          + this.$route.params.businessId
      });
    },
  }
}
</script>

<style>
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
</style>
