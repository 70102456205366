<template>
  <div>
    <div v-if="scrollingLeft == true" :class="'d-flex justify-space-between'">
      <v-carousel continuous cycle height="350px" :interval="4000" delimiter-icon="mdi-minus" :show-arrows="false"
        hide-delimiters>
        <v-carousel-item v-for="(product, i) in spotlightProducts" :key="i">
          <v-card tile :class="'my-1 d-flex flex-column'" width="100%" max-height="340" :to="{
            name: 'product',
            params: {
              businessId: $route.params.businessId,
              productId: product.website_identification,
            },
          }">
            <div class="d-flex align-center white--text text-caption rounded ma-2" style="min-height: 7px"
              :style="'background-color: #' + product.category.color"></div>
            <div class="my-2 flex-grow-1 d-flex align-center" style="min-height: 100px">
              <div v-if="loadingSpotlight" style="width:100%" class="text-center">
                <v-progress-circular color="secondary" indeterminate :size="50" :width="2" />
              </div>
              <v-img v-else-if="product.images.length > 0" max-height="200px" max-width="100%" contain
                :src="$FILES_URL + product.images[0].url" />
            </div>
            <div class="ma-2 d-flex align-center">
              {{ endDescription(product.name) }}
            </div>
            <v-divider />
            <div v-if="product.price != 0.00" class="pa-2 d-flex flex-wrap justify-end">
              <v-spacer />
              <span :style="{
                color: product.promotion_price ? '#DD6666' : '#000',
                textDecoration: product.promotion_price
                  ? 'line-through'
                  : 'none',
              }">
                <span style="font-weight: 600; font-size: 12px !important">
                  R$
                </span>
                {{ $utils.formatMoney(product.price) }}
              </span>
              <template v-if="product.promotion_price">
                <div class="ml-2">
                  <span style="font-weight: 600; font-size: 12px !important">
                    R$
                  </span>
                  <b>
                    {{ $utils.formatMoney(product.promotion_price) }}
                  </b>
                </div>
              </template>
            </div>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div v-if="scrollingLeft == false" :class="'d-flex justify-space-between'" :style="{ 'flex-wrap': 'wrap', }">
      <v-card tile :style="$vuetify.breakpoint.width >= 690 ? 'max-width: 420px' : ''"
        :class="'my-1 d-flex flex-column'" :width="$vuetify.breakpoint.width >= 300 ? '49%' : '100%'"
        max-height="38                        0" v-for="(product, i) in products" :key="i" :to="{
          name: 'product',
          params: {
            businessId: $route.params.businessId,
            productId: product.website_identification,
          },
        }">
        <div class="d-flex align-center white--text text-caption rounded ma-2" style="min-height: 7px"
          :style="'background-color: #' + product.category.color" />

        <v-img v-if="product.images.length > 0" class="ma-2" :src="$FILES_URL + product.images[0].url"
          aspect-ratio="1" />

        <div class="ma-2" style="min-height: 100px">
          <span v-html="lowerTitle(product.name, product.description)"></span>
        </div>
        <v-divider />
        <div class="pa-2 d-flex flex-wrap justify-end">
          <v-spacer />
          <span :style="{
            color: product.promotion_price ? '#DD6666' : '#000',
            textDecoration: product.promotion_price ? 'line-through' : 'none',
            fontWeight: product.promotion_price ? '400' : '600',
          }">
            <span v-if="product.price != 0.00" style="font-weight: 600; font-size: 12px !important"> R$ </span>
            <span v-if="product.price != 0.00"> {{ $utils.formatMoney(product.price) }}</span>
          </span>
          <template v-if="product.promotion_price">
            <div class="ml-2">
              <span style="font-weight: 600; font-size: 12px !important">
                R$
              </span>
              <b>
                {{ $utils.formatMoney(product.promotion_price) }}
              </b>
            </div>
          </template>
        </div>
      </v-card>
      <div v-if="!fullyLoaded" class="py-5 d-flex align-center text-center">
        <div class="d-flex align-center">
          <div v-if="tryAgain" class="py-5 d-flex align-center text-center">
            Erro inesperado ao carregar {{ $offerType() }}s.
            <br />
            <v-btn small color="error" class="mt-2" tile dark @click="getData()">
              Tentar novamente
            </v-btn>
          </div>
          <scroll-loader v-else :loader-method="getData" :loader-disable="fullyLoaded">
            <v-progress-circular indeterminate :size="20" :width="2" />
          </scroll-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "ProductList",
  props: {
    scrollingLeft: {
      type: Boolean,
      required: true,
    },
    categorySelected: {
      type: Object,
      required: false,
      default: null,
    },
    actualProduct: {
      type: Object,
      required: false,
    },
    callBackSpotlight: {
      type: Function,
      required: false,
    },
    callBackProduct: {
      type: Function,
      required: false,
    },
  },

  data: () => ({
    loadingSpotlight: false,
    tryAgain: false,
    loadingProducts: false,
    firstLoading: true,

    reloading: false,

    changeTimeout: undefined,
    textFilter: "",
    fullyLoaded: false,
    limit: 10,
    products: [],
    spotlightProducts: [],
  }),

  methods: {
    resetLoader() {
      if (this.scrollingLeft == false) {
        if (this.changeTimeout != undefined) {
          clearTimeout(this.changeTimeout);
        }
        this.changeTimeout = setTimeout(() => {
          if (this.loadingProducts) {
            this.resetLoader();
          } else {
            this.fullyLoaded = false;
            this.$set(this, "products", []);
          }
        }, 300);
      }
    },

    getCategoryId() {
      if (this.categorySelected != null) {
        return this.categorySelected.id;
      }
      return null;
    },

    endDescription(description) {
      if (this.scrollingLeft == true) {
        if (description.length > 39) {
          return description.substring(0, 39) + "...";
        } else {
          return description;
        }
      }
      else {
        if (description.length > 64) {
          return description.substring(0, 64) + "...";
        } else {
          return description;
        }
      }
    },

    lowerTitle(title, description) {
      if (this.scrollingLeft == true) {
        if (title.length > 39) {
          return title.substring(0, 39) + "...";
        } else {
          return title;
        }
      }
      else {
        if (title.length < 25) {
          return "<b>" + title + "</b> <br> " + description.substring(0, 44) + "...";
        } else if (title.length > 64) {
          return '<b>' + title.substring(0, 64) + "...</b>";
        } else {
          return "<b>" + title + "</b>";
        }
      }
    },

    getData() {
      if (this.firstLoading == true) {
        if (this.scrollingLeft == true) {
          this.getSpotlightProducts();
        }
        this.firstLoading = false;
      }
      else {
        this.getProducts();
      }
    },

    getSpotlightProducts() {
      this.loadingSpotlight = true;
      this.tryAgain = false;
      this.$axios
        .get("/web/products-spotlight/" + this.$route.params.businessId)
        .then((response) => {
          this.spotlightProducts = [...response.data];
        })
        .catch((error) => {
          console.log(error);
          this.$showMessage("error", "Erro ao carregar os " + this.$offerType() + "s");
          this.tryAgain = true;
        })
        .finally(() => {
          this.loadingSpotlight = false;
          this.callBackSpotlight(this.spotlightProducts);
        });
    },

    getProducts() {
      this.loadingProducts = true;
      this.tryAgain = false;
      this.$axios
        .patch("/web/products", {
          business_name: this.$route.params.businessId,
          category_id: this.getCategoryId(),
          limit: this.limit,
          offset: this.products.length,
          name: this.textFilter,
          actual_product: this.actualProduct?.website_identification ?? null,
        })
        .then((response) => {
          this.products = [...this.products, ...response.data];
          if (response.data == null || response.data.length < this.limit) {
            this.fullyLoaded = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$showMessage("error", "Erro ao carregar os " + this.$offerType() + "s");
          this.tryAgain = true;
        })
        .finally(() => {
          this.loadingProducts = false;
          this.callBackProduct(this.products);
        });
    },

    setSearch(text) {
      this.textFilter = text;
    },
  },
  created() {
    Vue.prototype.$setSearch = this.setSearch;
  },
  mounted() {
    this.getData();
  },
  watch: {
    categorySelected() {
      this.resetLoader();
    },
    textFilter() {
      this.resetLoader();
    },
    actualProduct() {
      this.resetLoader();
    },
  },
};
</script>