var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"fade-transition","max-width":"350"},on:{"click:outside":function($event){return _vm.callBackDialog(false)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"width":"100%","height":"100%"}},[_c('v-toolbar',{attrs:{"color":"black","tile":"","dark":"","elevation":"0"}},[_vm._v(" Contatos ")]),_c('v-card-text',{staticClass:"pa-2"},_vm._l((_vm.action_buttons),function(action_button,i){return _c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.action_buttons.length > 0),expression:"action_buttons.length > 0"}],key:i,staticClass:"mb-2",class:action_button.selected_icon == 'E-mail' ? 'black--text' :
          action_button.selected_icon == 'Telefone' ? 'black--text' :
          action_button.selected_icon == 'Site' ? 'black--text' : 'white--text',attrs:{"target":"_blank","color":action_button.selected_icon == 'Facebook' ? 'blue lighten-1' :
          action_button.selected_icon == 'Twitter' ? 'blue accent-4' :
          action_button.selected_icon == 'Instagram' ? 'pink accent-3' :
          action_button.selected_icon == 'TikTok' ? 'blue-grey darken-4' :
          action_button.selected_icon == 'Youtube' ? 'deep-orange accent-4' :
          action_button.selected_icon == 'Whatsapp' ? 'green' :
          action_button.selected_icon == 'Telegram' ? 'blue' :
          action_button.selected_icon == 'Outro' ? 'grey darken-4' : 'primary',"block":"","height":"50px"},on:{"click":function($event){return _vm.openLink(action_button)}}},[(action_button.icon_url)?_c('v-img',{staticClass:"mr-2",attrs:{"src":action_button.icon_url,"contain":"","max-height":"25","max-width":"25"}}):(action_button.selected_icon == 'E-mail')?_c('v-icon',{staticClass:"mr-1 black--text"},[_vm._v("mdi-email")]):(action_button.selected_icon == 'Telefone')?_c('v-icon',{staticClass:"mr-1 black--text"},[_vm._v("mdi-phone")]):(action_button.selected_icon == 'Site')?_c('v-icon',{staticClass:"mr-1 black--text"},[_vm._v("mdi-web")]):_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-store-plus")]),_vm._v(" "+_vm._s(action_button.selected_icon)+" ")],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){return _vm.callBackDialog(false)}}},[_vm._v("Fechar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }