<template>
  <div class="fill-height">
    <div v-if="business">
      <v-img width="100%" max-height="300px" :src="$FILES_URL + business.image_url" />
      <div class="pa-4">
        <h3>Olá, aqui é a {{ business.name }}.</h3>
        <span v-if="business.address != null" style="font-size: 13px">
          {{ business.address.city.name + ", " + business.address.state.uf }}
        </span>
        <v-divider class="pt-2 mt-2" />

        <p
          class="pt-2"
          style="font-size: 15px"
          v-html="$utils.parseTextToHtml(business.about)"
        />

        <v-divider class="pt-4 mt-2" />
        <div class="d-flex pb-4 justify-space-between">
          <v-btn class="flex-grow-1 black--text" color="primary" width="49%" height="60px" :to="{
            name: 'products',
            params: {
              businessId: $route.params.businessId,
            },
          }">
            {{ $offerType() + 's' }}
          </v-btn>
          <v-btn class="ml-3 black--text" :disabled="contacts.length == 0" color="primary" width="49%" height="60px"
            @click="contactDialog = true">
            Contatos
          </v-btn>
        </div>
        <MainActionButton style="width: 100%" class="py-5" />
        <h4 class="mb-1 mt-3 align-center justify-center text-center">VEJA MEUS DESTAQUES</h4>
        <span v-if="spotlightProducts.length == 0" class="mb-1 mt-3 d-flex justify-center text-center">Ops! Ainda
          estamos adicionando. Aguarde.</span>
        <ProductList :callBackSpotlight="setSpotlightProducts" :categorySelected="null" :scrollingLeft="true" />
        <ActionButtonsDialog :callBackButtons="setContacts" :dialog="contactDialog"
          :callBackDialog="setContactDialog" />
      </div>
    </div>
    <div v-else class="d-flex fill-height align-center justify-center text-center" :style="!$isMobile ? 'height: 600px' : ''">
      <v-progress-circular indeterminate v-if="loadingBusiness" color="secondary" />
      <div v-else-if="tryAgain" :style="!$isMobile ? 'height: 600px' : ''" :class="!$isMobile ? 'd-flex align-center' : ''">
      <div  class="py-10" >
        <h3>Não foi possível encontrar o negócio</h3>
        <v-btn class="mb-4 secondary" dark @click="getBusinessInfo()">
          Tente Novamente
        </v-btn>
      </div>
      </div>
      <div class="d-flex align-center" :style="!isMobile? 'height: 600px' : ''" v-else>
      <h3 >Este negócio não existe</h3>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButtonsDialog from "../components/ActionButtonsDialog.vue";
import ProductList from "../components/ProductList.vue";
import MainActionButton from "@/components/MainActionButton.vue";
export default {
  name: "Home",
  components: {
    ActionButtonsDialog,
    ProductList,
    MainActionButton,
  },
  data: () => ({
    loadingBusiness: false,
    loadingButton: false,
    tryAgain: false,

    favorite_button: null,
    business: null,
    spotlightProducts: [],
    contactDialog: false,
    contacts: [],
  }),

  methods: {
    setContactDialog(value) {
      this.contactDialog = value
    },

    setContacts(contacts) {
      this.contacts = contacts;
    },

    setSpotlightProducts(products) {
      this.spotlightProducts = products;
    },

    removeCharsNumber(number) {
      return number.replace(/\D/g, "");
    },

    openLink(action_button) {
      if (action_button.selected_icon == "Whatsapp")
        return (
          "http://api.whatsapp.com/send?phone=55" +
          this.removeCharsNumber(action_button.data)
        );
      else if (action_button.selected_icon == "E-mail")
        return "mailto:" + action_button.data;
      else if (action_button.selected_icon == "Telefone")
        return "tel:" + this.removeCharsNumber(action_button.data);
      else return action_button.data;
    },

    getBusinessInfo() {
      this.loadingBusiness = true;
      this.tryAgain = false;

      this.$axios
        .get("/web/business/" + this.$route.params.businessId)
        .then((response) => {
          this.business = response.data;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 404) {
            this.$showMessage("error", "Empresa inexistente");
          } else {
            this.$showMessage("error", "Erro ao carregar os dados do negócio");
            this.tryAgain = true;
          }
        })
        .finally(() => {
          this.loadingBusiness = false;
        });
    },

    getFavoriteButton() {
      this.loadingButton = true;
      this.tryAgain = false;

      this.$axios
        .get("/web/action-buttons-favorite/" + this.$route.params.businessId)
        .then((response) => {
          this.favorite_button = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$showMessage("error", this.$offerType() + " inexistente");
          } else if (error.response.status !== 403) { ///403 não tem action button favorito
            this.tryAgain = true;
            console.log(error);
            this.$showMessage("error", "Erro ao carregar o botão de favorito");
          }
        })
        .finally(() => {
          this.loadingButton = false;
        });
    },

  },
  created() {
    this.getBusinessInfo();
  },
  watch: {
  },
};
</script>
