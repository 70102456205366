var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.favorite_button)?_c('v-btn',{staticClass:"flex-grow-1",class:_vm.favorite_button.selected_icon == 'E-mail'
|| _vm.favorite_button.selected_icon == 'Telefone'
|| _vm.favorite_button.selected_icon == 'Site'
? 'black--text'
: 'white--text',attrs:{"color":_vm.favorite_button.selected_icon == 'Facebook' ? 'blue lighten-1' :
          _vm.favorite_button.selected_icon == 'Twitter' ? 'blue accent-4' :
          _vm.favorite_button.selected_icon == 'Instagram' ? 'pink accent-3' :
          _vm.favorite_button.selected_icon == 'TikTok' ? 'blue-grey darken-4' :
          _vm.favorite_button.selected_icon == 'Youtube' ? 'deep-orange accent-4' :
          _vm.favorite_button.selected_icon == 'Whatsapp' ? 'green' :
          _vm.favorite_button.selected_icon == 'Telegram' ? 'blue' :
          _vm.favorite_button.selected_icon == 'Outro' ? 'grey darken-4' : 'primary',"large":"","dark":"","target":"_blank"},on:{"click":function($event){return _vm.openLink(_vm.favorite_button)}}},[(_vm.favorite_button.icon_url)?_c('v-img',{staticClass:"mr-2",attrs:{"src":_vm.favorite_button.icon_url,"contain":"","max-height":"25","max-width":"25"}}):(_vm.favorite_button.selected_icon == 'E-mail')?_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-email")]):(_vm.favorite_button.selected_icon == 'Telefone')?_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-phone")]):(_vm.favorite_button.selected_icon == 'Site')?_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-web")]):_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-store-plus")]),_vm._v(" "+_vm._s(_vm.favorite_button.selected_icon)+" ")],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }