<template>
  <v-dialog 
    v-model="dialog" 
    transition="fade-transition" 
    max-width="350"
    @click:outside="callBackDialog(false)"
    >
    <v-card width="100%" height="100%">
      <v-toolbar color="black" tile dark elevation="0">
        Contatos
      </v-toolbar>
      <v-card-text class="pa-2">
        <v-btn 
          v-for="(action_button, i) in action_buttons" :key="i" 
          target="_blank" 
          class="mb-2" 
          :class="
            action_button.selected_icon == 'E-mail' ? 'black--text' :
            action_button.selected_icon == 'Telefone' ? 'black--text' :
            action_button.selected_icon == 'Site' ? 'black--text' : 'white--text'" 
          :color="
            action_button.selected_icon == 'Facebook' ? 'blue lighten-1' :
            action_button.selected_icon == 'Twitter' ? 'blue accent-4' :
            action_button.selected_icon == 'Instagram' ? 'pink accent-3' :
            action_button.selected_icon == 'TikTok' ? 'blue-grey darken-4' :
            action_button.selected_icon == 'Youtube' ? 'deep-orange accent-4' :
            action_button.selected_icon == 'Whatsapp' ? 'green' :
            action_button.selected_icon == 'Telegram' ? 'blue' :
            action_button.selected_icon == 'Outro' ? 'grey darken-4' : 'primary'"
          block height="50px" 
          v-show="action_buttons.length > 0" 
          @click="openLink(action_button)"
          >
            <v-img v-if="action_button.icon_url" :src="action_button.icon_url" contain max-height="25" max-width="25"
              class="mr-2" />
            <v-icon v-else-if="action_button.selected_icon == 'E-mail'" class="mr-1 black--text">mdi-email</v-icon>
            <v-icon v-else-if="action_button.selected_icon == 'Telefone'" class="mr-1 black--text">mdi-phone</v-icon>
            <v-icon v-else-if="action_button.selected_icon == 'Site'" class="mr-1 black--text">mdi-web</v-icon>
            <v-icon v-else class="mr-1">mdi-store-plus</v-icon>
          {{ action_button.selected_icon }}
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="black" text @click="callBackDialog(false)">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ActionButtonsDialog",
  props: {
    callBackButtons: {
      type: Function,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    callBackDialog: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    tryAgain: false,
    action_buttons: [],
  }),

  methods: {
    removeCharsNumber(number) {
      return number.replace(/\D/g, "");
    },

    newTab(url) {
      window.open(url, '_blank');
    },

    openLink(action_button) {
      if (action_button.selected_icon == "Whatsapp"){
        return this.newTab("http://api.whatsapp.com/send?phone=55" +
          this.removeCharsNumber(action_button.data));
      }
      else if (action_button.selected_icon == "E-mail")
        return window.location.href =  "mailto:" + action_button.data;
      else if (action_button.selected_icon == "Telefone")
        return window.location.href = "tel:" + this.removeCharsNumber(action_button.data);
      else if (action_button.selected_icon == "Twitter"){
        return this.newTab("https://twitter.com/" + action_button.data.substring(1));
      }
      else if (action_button.selected_icon == "Instagram"){
        return this.newTab("https://www.instagram.com/" + action_button.data.substring(1));
      }
      else if (action_button.selected_icon == "TikTok"){
        return this.newTab("https://www.tiktok.com/" + action_button.data);
      }
      else if(action_button.selected_icon == "Facebook" || action_button.selected_icon == "Youtube" || action_button.selected_icon == "Site"){
        return this.newTab(action_button.data);
      }
      else if (action_button.selected_icon == "Telegram"){
        return this.newTab("https://t.me/" + action_button.data.substring(1));
      }
      else {
        navigator.clipboard.writeText(action_button.data);
        this.$showMessage('secondary', 'Copiado para a área de transferência!');
        this.callBackDialog(false);
      }
    },

    getActionButtons() {
      this.loading = true;
      this.tryAgain = false;
      if (this.$route.params.businessId)
        this.$axios
          .get("/web/action-buttons/" + this.$route.params.businessId)
          .then((response) => {
            this.action_buttons = [...response.data];
          })
          .catch((error) => {
            if (error.response.status !== 404) {
              this.$showMessage("error", "Erro ao carregar os botões de ação");
              this.tryAgain = true;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  mounted() {
    this.getActionButtons();
  },
  watch: {
    "$route.params.businessId"() {
      this.getActionButtons();
    },
    action_buttons() {
      this.callBackButtons(this.action_buttons);
    },
  },
};
</script>
